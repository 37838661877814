import Trigger from '../Trigger';

export default class LeaveTrigger extends Trigger {

    static TRIGGER_NAME = 'leave';

    init() {
        const leaveHandler = (event) => {
            if ((event.clientY < 0 || event.clientX > window.innerWidth || event.clientY > window.innerHeight || event.clientX < 0)) {
                $(document).off('mouseleave', leaveHandler);
                this.execute();
            }
        };

        $(document).on('mouseleave', leaveHandler);
    }
}
