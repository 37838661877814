import Trigger from '../Trigger';

export default class ClickTrigger extends Trigger {
    static TRIGGER_NAME = 'click';
    static CLICK_STORAGE_KEY = 'upsell_popups_click_count';

    init() {
        this.clickCount = parseInt(localStorage.getItem(ClickTrigger.CLICK_STORAGE_KEY)) || 0;
        this.minClick = this.params.minClick || 3;

        const clickHandler = (e) => {
            this.clickCount++;
            if (this.clickCount >= this.minClick) {
                e.preventDefault();
                $('body').off('click', clickHandler);
                localStorage.removeItem(ClickTrigger.CLICK_STORAGE_KEY);
                this.execute();
            } else {
                localStorage.setItem(ClickTrigger.CLICK_STORAGE_KEY, this.clickCount);
            }
        };

        $('body').on('click', clickHandler);
    }
}
