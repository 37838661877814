import Trigger from './Trigger';
import crypto from "crypto";

export default class TriggerManager {

    /**
     * @param classes
     */
    constructor(classes) {
        this._triggerClasses = [...classes];
        this._triggerInstances = [];
    }

    /**
     *
     * @param name
     * @param callable
     * @param params
     * @param hash
     */
    on(name, callable, params = {}, hash = null) {

        let instance = null;

        for (const triggerClass of this._triggerClasses) {
            if (typeof triggerClass === 'function' && triggerClass.TRIGGER_NAME === name) {
                hash = hash || this._generateHash(triggerClass.TRIGGER_NAME, callable, params);
                instance = new triggerClass(callable, params, hash);
                break;
            }
        }

        this._triggerInstances.push({
            name,
            instance,
            params,
            callable
        });
    }

    _generateHash(name, callable, params) {
        const sha1 = crypto.createHash('sha1');
        sha1.update(`${name}_${callable.toString()}_${JSON.stringify(params)}`);
        return sha1.digest('hex');
    }

    _generateGroupHash(triggers, callable) {
        const sha1 = crypto.createHash('sha1');
        sha1.update(`${JSON.stringify(triggers)}_${callable.toString()}}`);
        return sha1.digest('hex');
    }

    /**
     * @param triggerName
     * @param params
     */
    execute(triggerName, params) {
        for (const {name, instance = null, callable} of this._triggerInstances) {
            if (name === triggerName) {
                if (instance && instance instanceof Trigger) {
                    instance.execute();
                } else if (typeof callable === 'function') {
                    try {
                        callable(params);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        }
    }

    /**
     *
     * @param group
     * @param callable
     * @param hash
     */
    onGroup(group, callable, hash = null) {

        hash = hash || this._generateGroupHash(group, callable);

        for (const [triggerName, params] of Object.entries(group)) {
            this.on(triggerName, function (params) {
                callable(params);
            }, params, hash);
        }
    }
}
