import Trigger from '../Trigger';
import {getScrollPercent} from "../helpers";

export default class ScrollTrigger extends Trigger {

    static TRIGGER_NAME = 'scroll';

    scrollPercent = this.params.scrollPercent || 75;

    init() {

        const scrollHandler = () => {
            if (Math.round(getScrollPercent()) >= this.scrollPercent) {
                $(window).off('scroll', scrollHandler);
                this.execute();
            }
        };

        $(window).on('scroll', scrollHandler);
    }
}
