export default class Trigger {

    /**
     * @type {String|null}
     */
    static TRIGGER_NAME = null;

    static STORAGE_KEY = 'upsell_popups';

    static INTERVAL_EXECUTE_DAYS = 1;

    _isExecuted = false;

    _hash = null;

    /**
     * @param callable
     * @param {Object} [params={}] - Additional parameters for the trigger (default: empty object).
     * @param {String|null}  hash
     */
    constructor(callable, params = {}, hash = null) {
        this._callable = callable;
        this.params = params;
        this._hash = hash;

        try {
            setTimeout(() => {
                if (!this.isExecuted()) {
                    this.init();
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    init() {

    }

    getInterval() {
        if (typeof this.params.interval !== 'number') {
            return this.constructor.INTERVAL_EXECUTE_DAYS;
        }
        return this.params.interval;
    }

    _hasExecuted() {

        if (this._isExecuted) {
            return true;
        }

        const hash = this._getHash();

        if (hash) {
            const data = JSON.parse(localStorage.getItem(this.constructor.STORAGE_KEY)) || {};

            if (data[hash]) {
                const {interval, date} = data[hash],
                    expirationTime = new Date(date),
                    currentTime = new Date();

                //interval !== this.getInterval() ||
                if (currentTime.getTime() >= expirationTime.getTime()) {
                    delete data[hash];
                    localStorage.setItem(this.constructor.STORAGE_KEY, JSON.stringify(data));
                } else {
                    return true;
                }
            }
        }

        return false;
    }

    _addExecuted() {
        this._isExecuted = true;

        const hash = this._getHash();

        if (hash) {
            const data = JSON.parse(localStorage.getItem(this.constructor.STORAGE_KEY)) || {},
                currentTime = new Date();

            //delete expired
            for (const [key, {date}] of Object.entries(data)) {
                const expirationTime = new Date(date);
                if (currentTime.getTime() >= expirationTime.getTime()) {
                    delete data[key];
                }
            }

            const interval = this.getInterval();
            currentTime.setTime(currentTime.getTime() + (interval * 24 * 60 * 60 * 1000));
            data[hash] = {interval: interval, date: currentTime};
            localStorage.setItem(this.constructor.STORAGE_KEY, JSON.stringify(data));
        }
    }

    _getHash() {
        return this._hash;
    }

    isExecuted() {
        return this._hasExecuted(this._getHash());
    }

    execute() {
        if (!this.isExecuted()) {
            if (typeof this._callable !== 'function') {
                throw new Error("Handle is not function.");
            }

            this._addExecuted();

            try {
                this._callable(this.params);
            } catch (e) {
                console.error(e)
            }
        }
    }
}
